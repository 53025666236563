<template>
  <div class="vg_wrapper">
    <el-card>
      <el-form :inline="true" size="mini" :model="searchForm" ref="searchForm">
        <el-row>
          <el-col :md="8">
            <el-form-item label="机器编号:">
              <el-select size="small" v-model="searchForm.mach_id" placeholder="请选择机器编号" clearable >
                <el-option
                    v-for="item in machList"
                    :key="item.id"
                    :label="item.param1"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="userInfo.acct_id==1">
            <el-form-item label="所属账套:" label-width="82px">
              <el-select
                v-model="searchForm.acct_id"
                filterable
                placeholder="请选择所属账套"
                clearable
                size="small"
              >
                <el-option
                  v-for="item in acctList"
                  :key="item.acct_id"
                  :label="item.acct_no"
                  :value="item.acct_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getMacpsNow()" class="vg_ml_16">查询</el-button>
              <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" :data="tableData" border v-loading="loading" @row-dblclick="dbClickJp" class="mtrTab">
            <el-table-column label="机器编号" prop="mach_no"/>
            <el-table-column label="机器状态" prop="macp_status" :formatter="formatMacpStatus" show-overflow-tooltip/>
            <el-table-column label="机检日期" :formatter="formatDate" show-overflow-tooltip/>
            <el-table-column label="机检时间" show-overflow-tooltip  :formatter="formatTime" />
            <el-table-column label="所属账套" prop="acct_no" v-if="userInfo.acct_id==1"/>
          </el-table>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import { macpAPI } from "@api/modules/macp";
import { acctAPI } from "@api/modules/acct";
import { optnAPI } from "@api/modules/optn";
import helper from "@assets/js/helper.js"
export default {
  name: "MtrlPush9001",
  data(){
    return{
      searchForm: {
        mach_id:null,
        acct_id:null,
        stff_name:null
      },
      tableData: [],
      loading: true,
      machList:[],
      userInfo:this.$cookies.get('userInfo'),
      acctList:[]
    }
  },
  created() {
    this.initData()
  },
  mounted() {
  },
  filters:{
    // 时间转换
    formatDate(row) {
      return helper.toStringDate(row)
    },
  },
  methods: {
    initData() {
      this.loading = true;
      this.getPushList()
      this.getCompType()
      this.getAcct()
    },
     //获取审批账套
    getAcct() {
      get(acctAPI.getAllAcctsV1)
        .then((res) => {
          if (res.data.code == 0) {
            this.acctList = res.data.data;
            return;
          }
          return this.$message.error(res.data.msg);
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取机器编号
    getCompType(){
      get(optnAPI.getAllContent,{perm_id : 10002})
      .then(res=>{
        if(res.data.code === 0) {
          this.machList = res.data.data
        }else{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
        }
      })
      .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    //获取信息
    getPushList(){
      get(macpAPI.getNotDoneMacp,{
        mach_id:this.searchForm.mach_id,
        acct_id:this.searchForm.acct_id,
      })
          .then(res=>{
            if(res.data.code === 0){
              this.tableData = res.data.data.list
              setTimeout(() => {
                this.loading = false
              }, 500);
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 查询方法
    getMacpsNow(){
      this.loading = true
      this.initData()
    },
    // 机器状态
    formatMacpStatus(row){
      if(row.macp_status === 0){
        return '正常'
      }else if(row.macp_status === 1){
        return '未检测'
      }else if(row.macp_status === 2){
        return '机检异常'
      }
    },
    // 反显机检日期
    formatDate(row){
      let date = new Date(parseInt(row.start_time) * 1000);
      let Y = date.getFullYear() + '-';
      let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
      return (Y + M + D);
      // return row.end_time
    },
    // 反显机检时间
    formatTime(row){
      let date = new Date(parseInt(row.start_time) * 1000);
      let date1 = new Date(parseInt(row.end_time) * 1000);
      let h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
      let m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
      let ho = (date1.getHours() < 10 ? '0' + date1.getHours() : date1.getHours()) + ':';
      let mo = (date1.getMinutes() < 10 ? '0' + date1.getMinutes() : date1.getMinutes());
      return (h + m  + '~' + ho + mo );
    },
    // 双击
    dbClickJp(row){
      post(macpAPI.updateScanById,{macp_id: row.macp_id})
          .then(res => {
            if (res.data.code === 0) {
              this.initData()
              this.jump('/macp_list',{perm_id:136,macp_id:row.macp_id})
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            }
          }).catch(res => {
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message: mg, type: tp});
      })
    },
    buttonRefresh(){
      this.searchForm = {
        mach_id:null,
        acct_id:null,
      };
      this.initData()
    }
  }
}
</script>

<style scoped lang="scss">
.vd_completed{
  color: $color-secondary-text;
}
.vd_elimg {
  margin-top: 5px;
  width: 60px;
  height: 60px;
}
.mtrTab {
  margin-top:10px ;
  cursor: pointer;
}
</style>